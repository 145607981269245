<template>
  <div
    class="heading-component"
    :class="{ 'pb-10': size === 'large', 'pb-6': size !== 'large' }"
  >
    <h2 class="heading-heading" :class="size">
      {{ title }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "HeadingComponent",
  props: {
    title: String,
    size: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.heading-component {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .heading-heading {
    &.large {
      font-size: 36px;
    }
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin: 0px;
    font-size: 18px;
    line-height: 1.5;
    font-family: Calibri, sans-serif;
    font-weight: 400;
    color: black;
    padding-right: 8px;
  }
  .title-sep-container {
    position: relative;
    height: 6px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    .title-sep {
      position: relative;
      display: block;
      border-style: solid;
      border: 0 solid #e7e6e6;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      border-color: #aa212c;
      height: 6px;
      border-bottom-width: 0px;
      border-top-width: 0px;
      width: 100%;
    }
  }
}
</style>
