<template>
  <div
    class="px-8 py-10 container risk-management"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Risk Management" size="large" />
    <img src="@/assets/riskmanagement-slide.min.jpg" alt="Risk Management" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 pt-10">
        <TitleComponent title="Risk Management Services" />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-2/3">
        <div class="paragraph pt-10 pl-8">
          <HeadingComponent
            title="Internationally Recognized Engineers and Scientists"
          />
          <Paragraph>
            <p>
              Safety Management Services, Inc. (SMS™) is internationally
              recognized for providing a variety of risk management services to
              a wide range of commercial clients and government agencies. SMS is
              a specialized team of highly qualified engineers and scientists
              who have experience applying risk management protocol to ensure
              safe processing and handling of explosives and hazardous
              chemicals.
            </p>
          </Paragraph>
          <HeadingComponent
            title="Approach Focuses Engineering Controls of Credible Risk Scenarios"
          />
          <Paragraph>
            <p>
              Our approach to risk management emphasizes an engineering approach
              to: 1) minimizing exposure of personnel, 2) minimizing quantities
              of hazardous materials, 3) “safety by design,” 4) accurate
              procedures and standards, and 5) rigorous training of personnel.
              Proper risk management focuses not only on normal
              operations/conditions but also on abnormal operations/conditions,
              equipment design, human factors, standard operating and
              contingency procedures, maintenance operations, and facility
              design and siting.
            </p>
            <p>
              The SMS approach to risk management focuses resources on
              addressing critical and credible failure scenarios. This is
              accomplished by prioritizing operations/equipment based on
              perceived risk and material type and quantity, identifying and
              ranking potential hazards using qualitative methodologies (e.g.,
              JSA, HAZOP, FMEA, etc.), and then using quantitative methodologies
              (e.g., probability risk assessment, Fault Tree, event
              probabilities, PROBIT analysis, etc.) to evaluate the critical
              scenarios. SMS provides Hazards Analysis support for a
              considerable number of clients on a diverse array of process.
            </p>
          </Paragraph>
          <HeadingComponent title="SMS has Unique Capabilities" />
          <Paragraph>
            <p>
              SMS has unique capabilities and experience comparing in-process
              energies to the response of energetic material to the stimuli. We
              have developed effective methods for measuring, calculating,
              and/or modeling the normal and potential abnormal in-process
              energies. Our methodology allows us to compare this information
              with the energetic material response data, which is also in
              engineering units, and determine the probability of an initiation
              and incident.
            </p>
            <p>
              Please contact us by using the chat window at the bottom right of
              the screen, calling 801-567-0456, or filling out the
              <router-link class="text-red-800 link" to="/contact"
                >form here.</router-link
              >
            </p>
          </Paragraph>
        </div>
      </div>
    </div>
    <div class="pt-8 flex flex-wrap image-row-link">
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-none imageframe-2 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/risk-management/process-hazards-analysis"
              aria-label="PHA"
              ><img
                src="@/assets/RiskManagement/PHA-min.jpg"
                width="242"
                alt=""
                class="img-responsive wp-image-12301"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              Process Hazards Analysis
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-none imageframe-3 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/risk-management/regulatory-compliance-audits/risk-management-programs"
              aria-label="RMP"
              ><img
                src="@/assets/RiskManagement/Arch-Smooth-min.png"
                width="192"
                alt=""
                class="img-responsive wp-image-12301"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              Risk Management Programs
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-4 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/risk-management/d3-operations"
              aria-label="d3support"
              ><img
                src="@/assets/RiskManagement/d3support-min.jpg"
                width="242"
                height="155"
                alt=""
                class="img-responsive wp-image-12301"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              D3 Operational Support
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-5 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/risk-management/facility-siting-and-design"
              aria-label="Facility Siting and Design"
              ><img
                src="@/assets/RiskManagement/facilitysiting-min.jpg"
                width="242"
                height="155"
                alt="Facility Siting and Design"
                class="img-responsive wp-image-12294"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              Facility Siting and Design
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent";
import Paragraph from "../../components/Paragraph";
import PanelGroupComponent from "../../components/PanelGroupComponent";
import HeadingComponent from "../../components/HeadingComponent";

export default {
  metaInfo: {
    title: "Risk Management",
    titleTemplate: "%s | Safety Management Services, Inc. | Hello",
    meta: [
      {
        name: "description",
        content:
          "SMS has unique capabilities to use quantitative and qualitative methodologies to manage risk in energetic processes."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent,
    HeadingComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `The Safety Management Services, Inc. (SMS) approach to risk management
          focuses resources on addressing critical credible failure scenarios.
          This is accomplished by prioritizing operations/equipment based on
          perceived risk and material type and quantity, identifying and ranking
          potential hazards using qualitative methodologies, and then using
          quantitative methodologies to evaluate the critical scenarios. This
          type of review is known to industry as risk assessment or `,
          contentLink: {
            text: "Process Hazards Analysis (PHA)",
            to: "/risk-management/process-hazards-analysis"
          },
          content2: `.`,
          title: "Process Hazards Analysis"
        },
        {
          content1: `Our comprehensive approach to process safety management can be applied
          to any industrial process and tailored to include all applicable `,
          contentLink: {
            text: "regulatory elements",
            to: "/risk-management/regulatory-compliance-audits"
          },
          content2: `. Our uncompromising commitment to safety led to the development and
          successful implementation of process safety management practices long
          before such practices became the focus of government regulation.`,
          title: "Regulatory Compliance Audits"
        },
        {
          content1: `SMS has developed safe and reliable risk management systems to assess,
          categorize, decontaminate, disassemble equipment, and demolish
          facilities used for the processing of energetic materials. SMS has
          evaluated the suitability of various technologies for remote pipe
          cutting, boroscope inspection, thermal and/or chemical treatment, and
          destruction in place. Whether items are meant for reuse, recycle, or
          disposal, SMS can lead or assist your team to perform `,
          contentLink: {
            text: "D3 activities",
            to: "/risk-management/d3-operations"
          },
          content2: ` in a safe and efficient manner.`,
          title: "D3 Operational Support"
        },
        {
          content1: `SMS can assist you in understanding the regulations that apply to
          explosive operations. Our experienced staff understands civilian and
          military explosives manufacturing and storage regulations and their
          application. We specialize in interfacing with the individual
          regulating agencies (e.g., town, city, county, state, and the federal
          government) and ensuring your `,
          contentLink: {
            text: "facility siting and design",
            to: "/risk-management/facility-siting-and-design"
          },
          content2: ` meet all requirements.`,
          title: "Facility Siting and Design"
        },
        {
          content1: `SMS provides a wide range of environmental services in compliance with RCRA, Clean Air, Clean Water, and NESHAP. More info`,
          contentLink: {
            text: "here.",
            to: "/risk-management/environmental-services"
          },
          content2: ``,
          title: "Environmental Services"
        },
        {
          content1: `SMS personnel have extensive experience in participating with and
          leading `,
          contentLink: {
            text: "incident investigations",
            to: "/risk-management/incident-investigation"
          },
          content2: ` in the explosives, chemical, and manufacturing industries. We
          specialize in root cause analysis, reconstruction of events, and
          development of incident scenarios. SMS personnel are very versatile
          and can be quickly mobilized to your facility should the need arise.`,
          title: "Incident Investigation"
        },
        {
          content1: `Our staff is knowledgeable and experienced in a wide range of safety
            related topics and has conducted training seminars/ workshops in
            many aspects of process safety management. `,
          contentLink: {
            text: "Training courses",
            to: "/training"
          },
          content2: ` are tailored to meet the specific needs of the client company and
            its employees.`,
          title: "Personnel Training"
        },
        {
          content1: `SMS has considerable experience in providing `,
          contentLink: {
            text: "Explosive Safety Standards",
            to: "/risk-management/explosive-safety-standards"
          },
          content2: ` to clients. These standards include the following:
          <ul>
            <li>Processing Standard</li>
            <li>Tooling and Equipment Standards</li>
            <li>Facility Standards</li>
            <li>Incident Investigation Standards</li>
            <li>Training Standards</li>
            <li>Procedure Standards</li>
          </ul>`,
          title: "Explosive Safety Standards"
        },
        {
          content1: `SMS can assist you in completing an `,
          contentLink: {
            text: "Offsite Consequence Analysis",
            to: "/risk-management/offsite-consequence-analysis"
          },
          content2: ` including dispersion calculations and assistance in working with
          government agencies. If your facility stores or uses a chemical on the
          EPA regulated chemicals list above its threshold quantity, please
          contact us for further information.`,
          title: "Offsite Consequence Analysis"
        },
        {
          content1: `SMS can assist you in identifying and mitigating a combustible dust
          hazard through site surveys, `,
          contentLink: {
            text: "combustible dust testing",
            to: "/testing/sensitivity-testing/dust-explosibility"
          },
          content2: `, dust hazards analysis, and engineering controls.`,
          title: "Combustible Dust"
        },
        {
          content1: `Safety Management Services, Inc. (SMS) provides `,
          contentLink: {
            to: "/risk-management/arc-flash-hazards-analysis",
            text: "arc flash services"
          },
          content2: ` to ensure compliance with NEC, NFPA 70E, IEEE 158,4 and OSHA
          requirements.`,
          title: "Arc Flash Analysis"
        },
        {
          content1: `SMS is qualified to assist chemical facilities by providing support in
          understanding the federal regulation, assisting in drafting a site SVA
          and/or SSP that will satisfy the regulations specified by the
          Department of Homeland Security while ensuring that the `,
          contentLink: {
            to: "/risk-management/chemical-facility-security",
            text: "chemical facility"
          },
          content2: ` is not overly burdened by compliance.`,
          title: "Chemical Facility Security"
        },
        {
          content1: `SMS works with clients to design a `,
          contentLink: {
            to: "/risk-management/mechanical-integrity",
            text: "mechanical integrity"
          },
          content2: ` program to the appropriate level of detail. We utilize a risk-based
          approach to mechanical integrity that meets OSHA regulatory
          requirements and establishes a framework for the prioritization of
          mechanical integrity activities.`,
          title: "Mechanical Integrity"
        },
        {
          content1: `Our SMS `,
          contentLink: {
            to: "/ergonomic-analysis",
            text: "ergonomic analysis"
          },
          content2: ` methodology provides practical solutions to ergonomic hazards
          encountered in a myriad of working conditions where cumulative trauma
          disorders (CTDs) / repetitive strain injuries (RSIs), acute soft
          tissue injuries, or other ergonomic concerns are present. We are
          experienced in providing ergonomic evaluations ranging from the
          office, chemical processing, manufacturing, mining, and explosives
          industries. Our ergonomic capabilities can assist safety, health, and
          engineering professionals in resolving ergonomic-related issues.`,
          title: "Ergonomic Analysis"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.risk-management {
  text-align: left;
  /*  p {
    color: #747474;
    font-size: 21px;
    line-height: 1.5;
    margin-bottom: 20px;
  }*/

  .image-row-link {
    h2 {
      font-size: 18px;
      color: #333333;
    }
  }
}
</style>
